import React from "react"

import Layout from 'gatsby-theme-yum-components/src/components/modules/Layout';
import BlockWrapper from "gatsby-theme-yum-components/src/components/technical/BlockWrapper";
import Button from "gatsby-theme-yum-components/src/components/atoms/Button";

const NotFoundPage = () => {

return (
  <Layout>
    <BlockWrapper block={{}}>
      <h1 className="inline-block text-2xl font-bold leading-none md:text-3xl md:leading-none font-display">404 – Seite nicht gefunden</h1>
      <div className="pt-8 prose max-w-none ">
      <p>Diese Seite existiert leider nicht.</p>
      </div>
      <Button className="mt-4" link="/">Zurück zur Startseite</Button>
      </BlockWrapper>
  </Layout>
)}

export default NotFoundPage
